* {
  /* background-color: black; */
  /* color: white; */
  font-family: Space Grotesk, sans-serif;
}

*::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

html {
  scroll-behavior: smooth;
}

.job-section-rich-text .ql-container {
  font-family: 'Space Grotesk' !important;
  font-size: 20px !important;
  color: rgba(229, 234, 255, 0.6) !important;
}

@media (min-width: 50px) and (max-width: 759px) {
  .job-section-rich-text .ql-container {
    font-size: 13px !important;
  }
}

body {
  background-color: #111112;
  color: white;
  /* For now */
  /* max-width: 1500px; */
  margin: 0 auto;
}

a {
  color: white;
}

.pac-container {
  color: white;
  background-color: #111112;
  border: 2px solid #7266ee;
}

.pac-container:after {
  /* content: "Powered by Google"; */
  /* font-size: 10px; */
  /* background-image: none !important; */
  /* background-image: url(`${process.env.PUBLIC_URL}/assets/powered_by_google_on_non_white_hdpi.png`) !important; */
}

.pac-item {
  color: white;
}

.pac-item:hover {
  color: white;
  background-color: rgba(204, 213, 255, 0.11);
}

.pac-matched {
  color: white;
}

.pac-item-query {
  color: white;
}

.pac-item-selected {
  background-color: #7266ee;
}

.DayPicker_transitionContainer {
  background-color: #1a1b1e !important;
  border: 2px solid rgba(204, 213, 255, 0.11);
}

.CalendarDay__default {
  border: none !important;
}

.CalendarDay__default:hover {
  background: #7266ee !important;
  border-radius: 100%;
}

.CalendarDay__selected {
  background: #7266ee !important;
  color: white;
  border-radius: 100%;
}

.CalendarMonthGrid {
  background-color: #1a1b1e !important;
}

.CalendarMonth_caption {
  color: white !important;
  font-size: 16px !important;
}

.DayPicker {
  background-color: #1a1b1e !important;
}

.CalendarMonth {
  background-color: #1a1b1e !important;
}

.CalendarDay.CalendarDay__default {
  background-color: #1a1b1e;
  color: white;
}

.carousel-root {
  flex: 1;
}

#videoaskModalWrapper .videoask-embed__modal_closeButton--35rbr {
  animation: none !important;
}

@media screen and (min-width: 759px) {
  #videoaskModalWrapper {
    width: calc(100% - 64px) !important;
    height: calc(100% - 64px) !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 22px !important;
  }
}
