@font-face {
  font-family: 'inter';
  src: url(./inter/Inter-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Space Grotesk';
  src: local('Space Grotesk'), local('SpaceGrotesk'), url(./spacegrotesk/SpaceGrotesk-Regular.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  src: local('Space Grotesk'), local('SpaceGrotesk'), url(./spacegrotesk/SpaceGrotesk-Bold.otf) format('opentype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'thexplace';
  src: url('./glyph/thexplace.eot?v3vzt1');
  src: url('./glyph/thexplace.eot?v3vzt1#iefix') format('embedded-opentype'),
    url('./glyph/thexplace.ttf?v3vzt1') format('truetype'), url('./glyph/thexplace.woff?v3vzt1') format('woff'),
    url('./glyph/thexplace.svg?v3vzt1#thexplace') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'thexplace' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vetted:before {
  content: '\2714';
}
.icon-vettinginprogress:before {
  content: '\23f0';
}
